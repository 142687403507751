@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Barlow'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  background-color: #F2F2f2;


  /* font-family: 'Barlow', sans-serif; */
}

html {
  min-height: 100%;
  scroll-behavior: smooth;
  font-family: 'Barlow';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  display: block;
  width: 330px;
}

.horizontal-center {
  left: 50%
}





.about-pic {
  float: right;
  margin: 0 1em 1em 0;
}

.error-image {
  border: none;
  display: block;
  margin: 0 auto;
  width: 330px;
}

.error-text {
  font-size: 1.5em;
}





/* Phantom divs to push other divs */
.phantom {
  display: block;
  padding: 50px;
  height: 60px;
  width: 100%;
}

.push-down-padding {
  padding-top: 50px;
  margin: 0;
}
/* End */
