.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  margin: auto;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  text-align: center;
  display: block;
  width: 330px;
}

.horizontal-center {
  left: 50%
}





.about-pic {
  float: right;
  margin: 0 1em 1em 0;
}

.error-image {
  border: none;
  display: block;
  margin: 0 auto;
  width: 330px;
}

.error-text {
  font-size: 1.5em;
}





/* Phantom divs to push other divs */
.phantom {
  display: block;
  padding: 50px;
  height: 60px;
  width: 100%;
}

.push-down-padding {
  padding-top: 50px;
  margin: 0;
}
/* End */